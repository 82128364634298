import axios from 'axios';
import { Toast, Dialog } from 'vant';
// 创建一个axios实例
const instance = axios.create({
    baseURL: 'http://admin.bjthua.com/api', // 设置API的基础URL
    timeout: 5000, // 设置超时时间
});

// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        // 在请求发送之前可以做一些处理，例如添加请求头等
        return config;
    },
    (error) => {
        // 处理请求错误
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    (response) => {
        // 对响应数据进行处理
        return response.data;
    },
    (err) => {

        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    err.message = '错误请求'
                    break;
                case 401:
                    err.message = '未授权，请重新登录'
                    break;
                case 403:
                    //   window.location.href='/#/login';
                    //   sessionStorage.clear();
                    err.message = '登陆已过期,请重新登陆'
                    break;
                case 404:
                    err.message = '请求错误,未找到该资源'
                    break;
                case 405:
                    err.message = '请求方法未允许'
                    break;
                case 408:
                    err.message = '请求超时'
                    break;
                case 500:
                    err.message = '服务器端出错'
                    break;
                case 501:
                    err.message = '网络未实现'
                    break;
                case 502:
                    err.message = '网络错误'
                    break;
                case 503:
                    err.message = '服务不可用'
                    break;
                case 504:
                    err.message = '网络超时'
                    break;
                case 505:
                    err.message = 'http版本不支持该请求'
                    break;
                default:
                    err.message = `连接错误${err.response.status}`
            }
        } else {
            if (err.message !== "请勿重复点击") {
                err.message = "连接到服务器失败"
            }
        }


        // console.log(err)

        // 显示错误提示
        Toast({
            message: err.message,
            position: 'top',
        });
        // 处理响应错误
        return Promise.reject(err);
    }
);



// 封装具体的接口请求
const api = {
    // getUserInfo() {
    //     return instance.get('//system/relics/h5list');
    // },

    login(params) {
        return instance.get(`/system/relics/h5list?id=${params.id}`);
    },
    // 其他接口请求...
};
export default api;
