<template>
    <div class="kaogu_box">
        <div class="centent">
            <!--内容区域 -->
            <div class="centent_boxtitle">
                款识
            </div>
            <div class="centent_box">
                <div class="box_centent">
                    <!-- 基本信息 -->
                    <div class="information">
                        <div class="title">
                            陶瓷款识特点
                        </div>
                    </div>
                    <div class="information" v-show="this.CulturalallData && this.CulturalallData.inscriptionImgUrlArray ">
                  <div v-for="(item,index) in this.CulturalallData.inscriptionImgUrlArray" :key="index">
                    <img v-show="item" :src="['http://admin.bjthua.com/api' + [item]]" alt="">
                  </div>
                        <table border="1">
                            <tr>
                                <td class="left">内容</td>
                                <td class="left">位置</td>
                                <td class="left">工艺</td>
                                <td class="left">边框</td>
                                <td class="left">排列</td>
                                <td class="left">字体</td>
                                <td class="left">字数</td>
                                <td class="left">发色</td>
                            </tr>
                            <tr>
                                <td class="left" style="font-weight: 500;">{{ CulturalallData && CulturalallData.inscriptionContent ? CulturalallData.inscriptionContent : "/"}}</td>
                                <td class="left" style="font-weight: 500;">{{ CulturalallData && CulturalallData.inscriptionPosition ? CulturalallData.inscriptionPosition : "/"}}</td>
                                <td class="left" style="font-weight: 500;">{{ CulturalallData && CulturalallData.inscriptionProcess ? CulturalallData.inscriptionProcess : "/"}}</td>
                                <td class="left" style="font-weight: 500;">{{ CulturalallData && CulturalallData.inscriptionBorder? CulturalallData.inscriptionBorder : "/" }}</td>
                                <td class="left" style="font-weight: 500;">{{ CulturalallData && CulturalallData.inscriptionArrangement? CulturalallData.inscriptionArrangement : "/" }}</td>
                                <td class="left" style="font-weight: 500;">{{ CulturalallData && CulturalallData.inscriptionFont ? CulturalallData.inscriptionFont : "/"}}</td>
                                <td class="left" style="font-weight: 500;">{{ CulturalallData && CulturalallData.inscriptionWordsNumber? CulturalallData.inscriptionWordsNumber : "/"	 }}</td>
                                <td class="left" style="font-weight: 500;">{{ CulturalallData && CulturalallData.inscriptionHairColor? CulturalallData.inscriptionHairColor : "/" }}</td>


                            </tr>
                        </table>
                    </div>
                    <!-- 结束 -->
                </div>
            </div>
            <!-- 遮罩 -->
            <div class="zhezhao">
                <img src="../imager/data/zhezhao.png" alt="">
            </div>
            <!-- 结束按钮 -->
            <div class="finish" @click="btnblock">
                <img src="../imager/data/delete.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
         
            count: false,
        };
    },

    computed: {
        ...mapState(['CulturalallData'])
    },
    mounted() {
        this.$store.commit("setData", 'b')
    },
    methods: {
        btnblock() {
            // console.log("我被触发了")
            this.$emit('numChange', this.count)
        }
    },
};
</script>

<style lang="scss" scoped>
.kaogu_box {
    overflow: hidden;
    position: absolute;
    z-index: 9999;
    top: 0;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    padding: 6vw;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .centent {
        position: relative;
        background: url('../imager/data/kaogu_back.png') no-repeat;
        background-size: 100%;
        height: 970px;
        width: 100%;
        margin-top: -200px;


    }

    .centent_boxtitle {
        width: 100%;
        height: 50px;
        position: absolute;
        // background: salmon;
        align-items: center;
        text-align: center;
        top: 35px;
        font-weight: 550;
        font-size: 32px;
        background: url('../imager/index/title.png');
        background-position: 20px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 8px;
    }

    .centent_box {
        width: 100%;
        height: 750px;
        position: absolute;
        // border: 1px solid red;
        top: 120px;
        box-sizing: border-box;
        padding: 0 10px;

        .box_centent {
            overflow-y: auto;
            // border: 1px solid red;
            height: 100%;
            box-sizing: border-box;
            padding: 0 20px;

            .information {
                margin-top: 30px;
                width: 100%;
                // height: 309px;
                 img{
                    width: 100%;
                 }
                .title {
                    margin-top: 40px;
                    text-align: center;
                    width: 280px;
                    color: #e4f7fb;
                    height: 61px;
                    font-size: 25px;
                    line-height: 61px;
                    letter-spacing: 6px;
                    font-weight: 500;
                    background-image: url('../imager/data/title2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                table {
                    margin-top: 20px;
                    margin-left: 5px;
                    width: 98%;
                    border-spacing: 0;
                    border-collapse: collapse;
                    font-size: 18px;
                }

                .left {
                    height: 80px;
                    width:11%;
                    margin-top: -5px;
                    border: 3px solid #6fd9f1;
                    text-align: center;
                    color: #eefcff;
                    letter-spacing: 5px;
                    font-weight: 550;

                }

                .row {
                    padding: 40px;
                    // text-indent: 40px;
                    text-align: left !important;
                }
                .rowimg {
                    width: 65% !important;
                    letter-spacing: 5px;
                    border: 1px solid #6fd9f1;
                    text-align: center;
                    color: #eefcff;
                  img{
                    width: 300px;
                    // height: 300px;
                  }
                }
                .right {
                    width: 65%;
                    letter-spacing: 5px;
                    border: 1px solid #6fd9f1;
                    text-align: center;
                    color: #eefcff;

                }
            }

            // 考古现场发现
            .information2 {
                width: 100%;
                margin-top: 43px;

                .title {
                    text-align: center;
                    width: 242px;
                    color: #e4f7fb;
                    height: 61px;
                    font-size: 25px;
                    line-height: 61px;
                    letter-spacing: 6px;
                    font-weight: 500;
                    background-image: url('../imager/data/title2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .look_img {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row wrap;

                    .img_box {
                        margin-top: 20px;
                        width: 280px;
                        height: 180px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url('../imager/data/img_border.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .imgone {
                        position: relative;
                        width: 267px;
                        height: 160px;
                    }

                }


            }
        }
    }
}

.finish {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 65px;
        // height: 1;
    }
}

// 遮罩
.zhezhao {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    img {
        width: 100%;
        // height: 1;
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 16px;
    border-radius: 25px;
    margin-right: 20px;
    background: #3582aa;
}

/*定义滚动条轨道
//  内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 25px;
    // background-color:#F5F5F5;
    background-color: rgb(0, 0, 0, 0.1);
    // border: 1px solid #81aac9;

}

/*定义滑块
//  内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: #21bddf;
    background-color: #21bddf;
}

.kg {
    position: absolute;
    z-index: 9999;
}

.imgcenter {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 600px;
    }
}

.titlte_introduce {
    margin-top: 40px;
    color: #21bddf;
    font-size: 30px;
    font-weight: 600;
}
</style>