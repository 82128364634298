import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueTouch from 'vue-touch'
import Vuex from 'vuex';
import axios from 'axios';
import Vant from 'vant';

import store from './store/index'
import 'vant/lib/index.css';
import 'animate.css';
// import './utils/rem'
Vue.config.productionTip = false
Vue.use(VueTouch, {name: 'v-touch'})
Vue.use(Vant);
Vue.use(Vuex);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
