<template>
	<!-- v-if="isLook" -->
	<div class="content" v-if="isLook" v-cloak>
		<!-- 加载页面 -->
		<!-- 背景 -->
		<div class="index_bcl">
			<img src="../imager/index/bg.gif" mode="" class="img1">
			<img src="../imager/index/back.jpg" mode="" class="img2">
		</div>
		<!-- 模型 -->
		<Renders  style="position: relative;" :pathCurl="0" :routerpathurl="false" @numChange="getNewCount"></Renders>
		<!-- 内容太 -->
		<div v-if="this.loading == 'loading'">
			<div class="ceneten">
				<!--文物标题  -->
				<div :class="cultural_title" v-if="CulturalallData">
					<p class="chinese_title"> {{ CulturalallData.culturalRelicsName }}</p>
					<p class="english_title"> {{ CulturalallData.culturalRelicsEnglishName }} </p>
				</div>
				<!-- 表格-->
				<div :class="index_table">
					<div class="index_table_yab">
						<i class="top"></i><i class="bottom"></i>
						<table>
							<thead>
								<tr>
									<td style="border-bottom: 0px solid;">
										<div>收藏单位</div>
									</td>
									<!-- <td style="border-bottom: 0px solid;">
										<div>收藏编号</div>
									</td> -->
									<td style="border-bottom: 0px solid;">
										<div>文物来源</div>
									</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<div>{{ CulturalallData.collectionUnitsValue }} </div>
									</td>

									<td>
										<div>{{ CulturalallData.culturalRelicsSourceValue }} </div>
									</td>
								</tr>
							</tbody>
							<div class="panel-footer"></div>
						</table>
					</div>

				</div>
				<!-- 时间线 -->
				<div :class="time_line">
					<!-- {{ time }}  -->
					<p v-show="this.CulturalallData.dynasty == '唐'"  class="time_1">约 {{ CulturalallData.yearStart }}-{{ CulturalallData.yearEnd }} </p>
					<p  v-show="this.CulturalallData.dynasty == '宋'" class="time_p2">约 {{ CulturalallData.yearStart }}-{{ CulturalallData.yearEnd }} </p>
					 <p  v-show="this.CulturalallData.dynasty == '元'"  class="time_p3">约 {{ CulturalallData.yearStart }}-{{ CulturalallData.yearEnd }} </p>
					<p v-show="this.CulturalallData.dynasty == '明'"  class="time_p4">约 {{ CulturalallData.yearStart }}-{{ CulturalallData.yearEnd }} </p>
					<p  v-show="this.CulturalallData.dynasty == '清'"  class="time_p5">约 {{ CulturalallData.yearStart }}-{{ CulturalallData.yearEnd }} </p>
					<div v-show="this.CulturalallData.dynasty == '唐'" class="anbordertang"></div>
					<div   v-show="this.CulturalallData.dynasty == '宋'"  class="anbordersong"></div>
					<div v-show="this.CulturalallData.dynasty == '元'" class="anborderyuan"></div>
					<div v-show="this.CulturalallData.dynasty == '明'" class="anborderming"></div>
					<div v-show="this.CulturalallData.dynasty == '清'" class="anborderqing"></div>

					<img src="../imager/index/time.png" alt="">
				
				</div>
				<!-- 器物简述 -->
				
				<div :class="dofi">
					<img src="../imager/index/dofi.png" alt="">
				</div>
				<!-- 第二页图片加载 -->
				<img src="../imager/data/backgound.jpg" alt="" class="jiazai">
			</div>
			<!-- 介绍 -->
			<div :class="introduce" v-if="CulturalallData">
				<div class="line">
						<div class="line_border">
						</div>
					</div>
				<div class="cententext">
					<div>
						{{ CulturalallData.implementsPaper ? CulturalallData.implementsPaper : "暂无简述~" }}
					</div>
				</div>
			</div>
			<!-- 底部栏 -->
			<!-- <div :class="bottomactive">
			</div> -->
			<Bttom></Bttom>
		
		</div>
	</div>
</template>

<script>
import anime from '../utils/anime.es'
// THREE
import Bttom from '@/components/bottom.vue'
import Renders from '@/components/render3D.vue'
import api from '../utils/api';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
	components: {
		Bttom, Renders
	},
	data() {
		return {
			// 获取页面的id
			id: null,
			title: 'Hello',
			tabdata: [{
				name: "1",
				img1: require("../imager/index/1.png"),
				img2: require("../imager/index/a1.png")
			}, {
				name: "2",
				img1: require("../imager/index/2.png"),
				img2: require("../imager/index/a2.png")
			}, {
				name: "1",
				img1: require("../imager/index/3.png"),
				img2: require("../imager/index/a3.png")
			}, {
				name: "2",
				img1: require("../imager/index/4.png"),
				img2: require("../imager/index/a4.png")
			}, {
				name: "1",
				img1: require("../imager/index/5.png"),
				img2: require("../imager/index/a5.png")
			}],
			active: 0,
			camera: null,
			scene: null,
			renderer: null,
			controls: null,
			object: null,
			renderer: null,
			texture: null,
			texture2: null,
			mesh: null,
			mesh2: null,
			groudp: null,
			spend: 0.005,
			bar: null,
			loading: "loading",
			// 加载背景2
			lodingtwo: false,
			// 定义底部动画
			bottomactive: "none",
			// 文物标题
			cultural_title: "cultural_title",//animate__animated animate__fadeInDown
			// 表格
			index_table: "index_table",// animate__animated animate__fadeInLeftBig
			// 时间线
			time_line: "time_line",//animate__animated animate__fadeInLeftBig
			// 器物简述
			dofi: "dofi",// animate__animated animate__fadeInLeftBig
			// 介绍
			introduce: "introduce",//animate__animated animate__fadeInLeftBig
			// 定义一个旋转速度
			durationList: {
				duration1: 7500,//从外到内第一层旋转一周时间
				duration2: 6500,//从外到内第二层旋转一周时间
				duration3: 5500,//从外到内第三层旋转一周时间
				duration4: 4500,//从外到内第四层旋转一周时间
			},
			groupbig: null,
			pathurl: "",
			frame: null,
			isLook: false

		}
	},
	computed: {
		...mapGetters(['getId']),
		...mapState(['stotitle']),
		...mapState(['briefntroduction']),
		...mapState(['time']),
		...mapState(['CulturalallData'])


	},
	beforeRouteEnter(to, from, next) {
		// console.log(to, from) // 可以拿到 from， 知道上一个路由是什么，从而进行判断
		next(vm => {
			if (from.path !== "/") {
				// vm.loading = "loadingnone"
				vm.bottomactive = ""
				// console.log("不加进度条", vm.bottomactive)
			}
		})
		//在next中写处理函数
	},
	created() {
		let id = this.$route.params.newsId;
		this.id = id;

		// console.log(id, "ID", typeof (id))
		let ids = localStorage.getItem("id");

		let exp = /^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/
		if (exp.test(id)) {
			// console.log("参数不的")
			this.updateId(id);
		} else {
			this.updateId(ids);
			// alert("非法id")
		}

		let ids1 = localStorage.getItem("id");
		this.id = ids1;

		// 请求
		// console.log(this.id, 'ididididididididid')
		let params = {
			id: this.id
		}
		api.login(params).then(rrs => {
			// console.log(rrs.rows[0], "res1111111111111111")
			let a = rrs.rows[0]
			if (rrs.code == 200) {
				// alert("接口请求成功")
				this.isLook = true;
				// console.log(this.isLook, 'this.isLook')
			}
			this.$store.commit("setData", {
				...a
			})
			if (a) {
				localStorage.setItem("datas", JSON.stringify(a));
			}

		})
		// 请求

		if (!ids1) {
			ids1 = id;
			// console.log('首次进入没有缓存，用url中的id代替');
		}
	},

	methods: {
		getNewCount(val) {
			// console.log(val)
			//  首页动画
			if (val == "none") {
				this.bottomactive = 'animate__animated animate__fadeInLeftBig animate__delay-2s	2s';
				this.cultural_title = 'cultural_title animate__animated animate__fadeInDown ';
				this.index_table = 'index_table animate__animated animate__fadeInDown';
				this.time_line = 'time_line animate__animated animate__fadeInLeftBig animate__delay-1s	1s';
				this.dofi = 'dofi animate__animated animate__fadeInLeftBig animate__delay-2s	2s';
				this.introduce = 'introduce animate__animated animate__fadeInLeftBig animate__delay-2s	2s';
			}
		},
		onMouseDown() {
			this.spend = 0
		},
		onMouseUp() {
			this.spend = 0.005
		},
		btnclick(e) {
			this.active = e
		},
		...mapActions(['updateId']),

	},
	mounted() {

		// if (ids1 == 1) {
		// 	this.pathurl = "Y13"
		// 	k = "Y13"
		// }
		// if (ids1 == 2) {
		// 	this.pathurl = "n1"
		// 	k = "n1"
		// }
		// if (ids1 == 3) {
		// 	this.pathurl = "Y181"
		// 	k = "Y181"
		// }
		// if (ids1 == 4) {
		// 	this.pathurl = "Y381"
		// 	k = "Y381"

		// }
		// if (ids1 == 5) {
		// 	this.pathurl = "Y402"
		// 	k = "Y402"
		// }
		// this.$store.dispatch('updateId',1);
		// console.log(this.$store)
		// window.addEventListener('touchstart', this.onMouseDown)
		// window.addEventListener('touchend', this.onMouseUp)
	},



}
</script>

<style lang="scss" scoped>
* {
	margin: 0;
	padding: 0;
}
[v-cloak] {
  display: none;
}
.content {
	width: 100%;
	min-height: 100vh;
	background: url('../imager/index/back.jpg') no-repeat;
	background-size: 100% 100%;
	// transform: translateZ(99PX);

}

.index_bcl {
	position: absolute;
	width: 100%;
	height: 100vh;
	overflow: hidden;

	img {
		width: 100%;
		height: 100vh;
		// height: 120vh;
	}

	.img1 {
		position: relative;
		top: 0;
		left: 0;
		z-index: -1;
		pointer-events: none;

		z-index: 99;

	}

	.img2 {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		pointer-events: none;
		// z-index: 99;

	}
}

// 底部栏
.ceneten {
	position: absolute;
	pointer-events: none;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
}

// 器物描述
.dofi {
	width: 100%;
	height: 150px;
	position: fixed;
	display: flex;
	justify-content: center;
	bottom: 5.5vh;
z-index: 9999;
	img {
		height: 100px;
		width: 290px;
		margin-top: -40px !important;
	}
}

// 文物标题
.cultural_title {
	position: absolute;
	top: 75px;
	width: 100%;
	height: 180px;
	background: url('../imager/index/title.gif') no-repeat;
	background-size: 100% 121%;
	background-position-y: -55px;

	.chinese_title {
		font-size: 48px;
		display: inline-block;
		width: 100%;
		height: 60px;
		display: flex;
		justify-content: center;
		font-weight: 650;
		letter-spacing: 10px;
		color: #f5fafe;
		background: url('../imager/index/title.png');
		background-size: 100% 100%;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.english_title {
		display: inline-block;
		width: 100%;
		height: 30px;
		float: left;
		display: flex;
		justify-content: center;
		margin-top: 10px;
		font-size: 20px;
		color: #88aed3;
	}
}

// 首页表格
.index_table {
	width: 100%;
	// height: 120px;
	position: absolute;
	top: 230px;
	display: flex;
	justify-content: center;

	.index_table_yab {
		position: relative;
		margin-left: 51px;
		margin-right: 51px;
		// border: 1px solid #517995;
		border-bottom: 0;
		overflow: hidden;

	}

	table {

		// width: 600px;
		border-collapse: collapse;
		text-align: center;
		font-size: 23px;
		color: #d3f3ff;
		letter-spacing: 5px;
		background: #07355a;

		&::before {
			position: absolute;
			top: -2px;
			left: -2px;
			content: "";
			width: 10px;
			height: 10px;
			border-top: 5px solid #dff8fd;
			border-left: 5px solid #dff8fd;
		}

		&::after {
			position: absolute;
			top: -2px;
			right: -2px;
			content: "";
			width: 10px;
			height: 10px;
			border-top: 5px solid #dff8fd;
			border-right: 5px solid #dff8fd;
		}


	}

	.index_table_yab .top {
		top: 0;
		right: -100%;
		background-image: linear-gradient(270deg,
				transparent,
				#03e9f4,
				transparent);
		animation: three 4s linear 3s infinite;
	}


	.index_table_yab::before {
		content: " ";
		position: absolute;
		width: 1px;
		height: 100%;
		top: -100%;
		left: 0;
		background-image: linear-gradient(0deg,
				transparent,
				#03e9f4,
				transparent);
		animation: two 4s linear infinite;
	}

	.index_table_yab::after {
		content: " ";
		position: absolute;
		width: 1px;
		height: 100%;
		bottom: -100%;
		right: 0;
		background-image: linear-gradient(360deg,
				transparent,
				#03e9f4,
				transparent);
		animation: four 4s linear 2s infinite;
	}

	.index_table_yab i {
		position: absolute;
		display: inline-block;
		height: 1px;
		width: 100%;
	}

	.index_table_yab .bottom {
		bottom: 0;
		left: -100%;
		background-image: linear-gradient(270deg,
				transparent,
				#03e9f4,
				transparent);
		animation: one 4s linear 1s infinite;
	}

	@keyframes one {
		0% {
			left: -100%;
		}

		50%,
		100% {
			left: 100%;
		}
	}

	@keyframes xs {
		0% {
			transform: scale(1);
		}

		25% {
			transform: scale(1.1);
		}

		50% {
			transform: scale(1.2);
		}

		75% {
			transform: scale(1.1);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes two {
		0% {
			top: -100%;
		}

		50%,
		100% {
			top: 100%;
		}
	}

	@keyframes three {
		0% {
			right: -100%;
		}

		50%,
		100% {
			right: 100%;
		}
	}

	@keyframes four {
		0% {
			bottom: -100%;
		}

		50%,
		100% {
			bottom: 100%;
		}
	}

	thead {
		height: 55px;
		border-collapse: collapse;
		// border: 1px solid #517995;
		// background: #01548f;
		padding: 10px;

		tr {
			td {

				div {
					background: #01548f;
					margin: 0 3px;
					height: 44px;
					line-height: 42px;
					padding: 0 15px;
					margin: 5px;
				}

				div:nth-child(2) {
					background: #01548f;
					margin: 0 1px;
					height: 44px;
					line-height: 42px;
				}
			}
		}
	}

	tbody {
		border-top: 0px solid;

		tr {
			td {

				div {
					padding: 14px 10px;
				}
			}
		}
	}

	td {
		border: 1px solid #517995;



	}

	.panel-footer {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;

		&::before {
			position: absolute;
			bottom: 0;
			left: -2px;
			content: "";
			width: 10px;
			height: 10px;
			border-bottom: 5px solid #dff8fd;
			border-left: 5px solid #dff8fd;
		}

		&::after {
			position: absolute;
			bottom: 0;
			right: -2px;
			content: "";
			width: 10px;
			height: 10px;
			border-bottom: 5px solid #dff8fd;
			border-right: 5px solid #dff8fd;
		}
	}
}

// 时间线
.time_line {
	width: 100%;
	height: 114px;
	position: fixed;
	bottom:23vh;

	.time_p2 {
		color: #c7e9fa;
		position: absolute;
		// right: 120px;
		left: 33.2vw;
		top: -50px;
		font-size: 32px;
	}
	.time_p3 {
		color: #c7e9fa;
		position: absolute;
		// right: 120px;
		left: 52.5vw;
		top: -50px;
		font-size: 32px;
	}

	.time_p4 {
		color: #c7e9fa;
		position: absolute;
		// right: 120px;
		right: 26vw;
		top: -50px;
		font-size: 32px;
	}

	.time_p5 {
		color: #c7e9fa;
		position: absolute;
		top: -50px;
		right: 11vw;
		font-size: 32px;
	}

	.time_1{
		color: #c7e9fa;
		position: absolute;
		// right: 120px;
		left: 12.5vw;
		top: -50px;
		font-size: 32px;
	}



	.anbordertang {
		width: 2.33333vw;
		height: 2.33333vw;
		display: inline-block;
		// background: #88f2ff;
		background: white;
		border-radius: 50%;
		position: absolute;
		left: 12.5vw;
		top: 1.33333vw;
		animation: xs 2s linear 1s infinite;
		opacity: 0.85;
	}

	.anbordersong {
		width: 2.33333vw;
		height: 2.33333vw;
		display: inline-block;
		// background: #88f2ff;
		background: white;
		border-radius: 50%;
		position: absolute;
		left: 33.2vw;
		top: 1.33333vw;
		animation: xs 2s linear 1s infinite;
		opacity: 0.85;
	}

	.anborderyuan {
		width: 2.33333vw;
		height: 2.33333vw;
		display: inline-block;
		// background: #88f2ff;
		background: white;
		border-radius: 50%;
		position: absolute;
		left: 52.5vw;
		top: 1.33333vw;
		animation: xs 2s linear 1s infinite;
		opacity: 0.85;
	}

	.anborderming {
		width: 2.33333vw;
		height: 2.33333vw;
		display: inline-block;
		// background: #88f2ff;
		background: white;
		border-radius: 50%;
		position: absolute;
		right: 26vw;
		top: 1.33333vw;
		animation: xs 2s linear 1s infinite;
		opacity: 0.85;
	}


	.anborderqing {
		width: 2.33333vw;
		height: 2.33333vw;
		display: inline-block;
		// background: #88f2ff;
		background: white;
		border-radius: 50%;
		position: absolute;
		right: 10vw;
		top: 1.33333vw;
		animation: xs 2s linear 1s infinite;
		opacity: 0.85;
	}

















	img {
		width: 100%;
	}

	.line {
		width: 100%;

		.line_border {
			width: 100%;
		}

		// position: absolute;
		// top: -88px;
	}
}

//  介绍
.introduce {
	width: 100%;
	position: absolute;
	bottom: 3.5vh;
	height: 21vh;
	background: url('../imager/index/text.png') no-repeat;
	background-size: 100% 100%;
	display: flex;
	// justify-content: center;
	flex-wrap: wrap;

	.cententext {
		width: 96%;
		height: 100px;
		color: #f1faff;
		overflow-y: scroll;
		margin-top: 30px;
		font-size: 25px;
		text-indent: 40px;
		letter-spacing: 2px;
		line-height: 35px;
		display: flex;
		justify-content: center;

		div {
			width: 93%;
		}
	}

}

::-webkit-scrollbar {
	width: 16px;
	height: 16px;
	border-radius: 25px;
	// background:#F5F5F5;
}

/*定义滚动条轨道
//  内阴影+圆角*/
::-webkit-scrollbar-track {
	border-radius: 25px;
	// background-color:#F5F5F5;
	background-color: rgb(0, 0, 0, 0.1);
	border: 1px solid #81aac9;

}

/*定义滑块
//  内阴影+圆角*/
::-webkit-scrollbar-thumb {
	height: 40px;
	border-radius: 10px;
	-webkit-box-shadow: #21bddf;
	background-color: #21bddf;
}


#obj {
	position: relative;

}




//加载页面
.loading {
	position: fixed;
	width: 100%;
	height: 100vh;
	z-index: 9999999;
	// opacity: 0.5;
	pointer-events: all;
	background-image: url('../imager/index/loading_bck.jpg');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	//  中间盒子
	.centent_box {
		width: 100%;
		height: 700px;
		// background: red;
		z-index: -4;
		display: flex;
		justify-content: center;

	}

	//   logo进度条
	.progress-bar {
		width: 115px;
		height: 170px;
		background-image: url('../imager/index/logo1.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
	}

	.progress-fill {
		width: 100%;
		height: 0%;
		background-image: url('../imager/index/bar-fill.png');

		background-repeat: no-repeat;
		background-position: bottom;
		position: absolute;
		background-size: 100%;
		z-index: -1;
		bottom: 1.7px;
		/* left: -1px; */
		transition: height 0.5s ease-in-out;
	}

	.bigbox {
		position: absolute;
		width: 115px;
		height: 170px;
		z-index: -3;
		// background: black;
	}

	// 百分比
	.percentage {
		width: 100%;
		height: 65px;
		position: absolute;
		// background: red;
		display: flex;
		justify-content: center;
		margin-top: 400px;
		font-size: 35px;
		letter-spacing: 4px;
		font-weight: 600;
		color: rgb(83, 186, 248);
	}


	// 标题
	.title {
		width: 100%;
		height: 65px;
		position: absolute;
		// background: red;
		display: flex;
		justify-content: center;
		margin-top: 280px;

		img {
			height: 100%;
		}
	}
}

// 隐藏
.loadingnone {
	display: none;
}

// 图片加载
.jiazai {
	display: none;

}


// 流光
// .line_border {
// 	/* border: 1px solid rgba(32, 254, 255, 0.3); */
// 	color: #36cee9;
// 	;
// 	position: relative;
// 	width: 400px;
// 	height: 20px;
// 	border: 20px;
// 	overflow: hidden;
// 	margin: 200px auto;
// }

.line_border::before {
	content: " ";
	position: absolute;
	width: 200%;
	height: 7px;
	border: 7px;
	top: 0;
	left: -100%;
	background-image: linear-gradient(90deg,
			transparent,
			#36cee9,
			transparent);
	animation: flow 6s linear infinite;
}

@keyframes flow {
	0% {
		transform: translateX(100%);
	}

	50% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}</style>
