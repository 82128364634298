<template>
  <div > 
    <div class="bottom_tab">
      <div class="tab_list_big">
        <div class="tab_list" v-for="(item, index) in this.tabdata" @click="btnclick(index)">
          <img class="bottom_img" :src="active === index ? item.img2 : item.img1">
          <div :class="active === index ? 'bottm_title' : 'titleactive'">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      tabdata: [{
        name: "文物初识",
        img1: require("../imager/index/1.png"),
        img2: require("../imager/index/a1.png")
      }, {
        name: "基因数据",
        img1: require("../imager/index/2.png"),
        img2: require("../imager/index/a2.png")
      },
       
      {
        name: "全景观物",
        img1: require("../imager/index/4.png"),
        img2: require("../imager/index/a4.png")
      }, {
        name: "延展信息",
        img1: require("../imager/index/5.png"),
        img2: require("../imager/index/a5.png")
      },
      // {
      //   name: "文物修复",
      //   img1: require("../imager/index/3.png"),
      //   img2: require("../imager/index/a3.png")
      // },
    ],
      active: 0

    }
  },
  onLoad() {

  },
  created() {
    if (sessionStorage.getItem('active')) {
      this.tabIndex = sessionStorage.getItem('active')
      // console.log('之前选中的底部菜单栏是：====>', this.tabIndex)
    }


    // this.$router.beforeEach((to, from, next) => {
    //         console.log(to);
    //         console.log(from.name);
    //         if(from.name==="home"){
    //           this.active==0
    //         }
    //         if(from.name==="home"){
    //           this.active==0
    //         }
    //         if(from.name==="Literature"){
    //           this.active==1
    //         }
    //         if(from.name==="data"){
    //           this.active==2
    //         }
    //         if(from.name==="pair"){
    //           this.active==3
    //         }
    //         if(from.name==="panoramic"){
    //           this.active==4}

    //         console.log(this.active)
    //         next()
    //     })
  },

  // watch: {
  //       $route(to, from){
  //           console.log('路由变化了')
  //           console.log('当前页面路由：' + to);
  //           console.log('上一个路由：' + from);
  //       },
  //   },

  methods: {

    btnclick(e) {
      this.active = e;
      // 路由跳转
      // console.log(e)
      // 跳转文物初识
      sessionStorage.setItem('active', this.active)
      if (e == 0) {
        this.$router.replace({ name: 'home' }).catch(err => {
        })
      }
      // 跳转基因数据
      if (e == 1) {
        this.$router.replace({ name: 'data' }).catch(err => {
        })
      }
   // 跳转文物修复

      // 跳转全景观物
      if (e == 2) {
        this.$router.replace({ name: 'panoramic' }).catch(err => {
        })
      }
      // 跳转文献资料
      if (e == 3) {
        this.$router.replace({ name: 'Literature' }).catch(err => {
        })
      }



      // // 跳转文物修复
      // if (e == 2) {
      //   this.$router.replace({ name: 'pair' }).catch(err => {
      //   })
      // }
      // // 跳转全景观物
      // if (e == 3) {
      //   this.$router.replace({ name: 'panoramic' }).catch(err => {
      //   })
      // }
      // // 跳转文献资料
      // if (e == 4) {
      //   this.$router.replace({ name: 'Literature' }).catch(err => {
      //   })
      // }
    }
  },

  watch: {
    $route: {
      handler: function (newval, oldVal) {
        // console.log(newval.meta.index)
        if (newval.meta.index || newval.meta.index === 0) {
          this.active = newval.meta.index
          sessionStorage.setItem('active', this.active)
        }
      },
      // 深度观察监听
      deep: true,
      // 直接触发一次
      immediate: true
    }
  }




















}
</script>

<style lang="scss" scoped>
.bottom_tab {
  position: fixed;
  width: 100%;
  height: 170px;
  bottom: 0;
  background: url('../imager/index/bottom.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  z-index: 99999;

  .tab_list_big {
    box-sizing: content-box;
    padding: 0 50px;
    display: flex;
    overflow: hidden;
  }

  .tab_list {
    // background: red;
    flex: 1;
    height: 170px;
    // margin-top: 5px;
    display: flex;
    justify-content: center;

    img {
      // width: 100%;
      height: 100%;
    }
  }
}

.tab_list:nth-child(3) {
  flex: 1;
  margin-top: 15px;
  height: 170px;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
    // width: 100%;
    height: 100%;
    margin-top: -15px;
  }
}

// 底部ico
.bottom_img {
  position: relative;
}

// 底部标题
.bottm_title {
  position: absolute;
  top: 110px;
  font-size: 18px;
  color: #c9fbfe;
}

.titleactive {
  position: absolute;
  top: 110px;
  font-size: 18px;
  color: #11457c;
}
</style>