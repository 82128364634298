<template>
    <div class="kaogu_box">
        <div class="centent">
            <!--内容区域 -->
            <div class="centent_boxtitle">
                彩料
            </div>
            <div class="centent_box">
                <div class="box_centent">
                    <div class="information">
                        <div class="title">
                            陶瓷釉彩类型科普
                        </div>
                        <table border="1">
                            <tr>
                                <td class="left" style="width: 50%;">彩绘瓷器类别</td>
                                <td class="left" style="width: 50%;">{{ CulturalallData && CulturalallData.paintedPorcelainCategory? CulturalallData.lowTemperatureGlazePorcelainGlazeMaterial : "/" }}</td>
                            </tr>
                        </table>

                            <table border="1" style="margin-top: 0; border-top: 1px solid red;">
                            <tr rolspan=1>
                                <td class="left" style="width: 20%;">彩绘类型</td>
                                <td class="left" style="width: 10%;">{{ CulturalallData && CulturalallData.colouredDrawingPatternTypes ? CulturalallData.colouredDrawingPatternTypes : "/"}}</td>
                                <td class="left" style="width: 20%;">青花彩绘发色</td>
                                <td class="left" style="width: 10%;">{{ CulturalallData && CulturalallData.blueWhiteHairColouredDrawingPattern? CulturalallData.blueWhiteHairColouredDrawingPattern : "/" }}</td>
                            </tr>
                            <tr>
                                <td class="left" style="width: 20%;">青花彩绘颜色</td>
                                <td class="left" style="width: 10%;">{{ CulturalallData && CulturalallData.blueWhitePaintColor	? CulturalallData.blueWhitePaintColor : "/" }}</td>
                                <td class="left" style="width: 20%;">其他彩绘发色</td>
                                <td class="left" style="width: 10%;">{{ CulturalallData && CulturalallData.otherHairColouredDrawingPattern? CulturalallData.otherHairColouredDrawingPattern : "/"	 }}</td>
                            </tr>

                        </table>
                    </div>
                  
                    <!-- 新加 -->
                    <div class="material_new">
                        <div class="titlebug" v-show="this.CulturalallData && this.CulturalallData.colorMaterialImplementsLocalFigure">
                            器物局部图
                        </div>
                        <div class="information imgcenter"
                            v-if="this.CulturalallData && this.CulturalallData.colorMaterialImplementsLocalFigure">
                            <img :src="['http://admin.bjthua.com/api' + [this.CulturalallData.colorMaterialImplementsLocalFigure]]"
                                alt="">
                        </div>
                        <div v-if="this.CulturalallData && this.CulturalallData.colorExtraData">
                            <div v-for="(item, index) in JSON.parse(this.CulturalallData.colorExtraData)" :key="index">
                                <div v-if="index == 'material' && JSON.stringify(item) !== '{}'">
                                    <div class="titlebug" style="margin-top: 30px;">
                                        SEM-EDS
                                    </div>
                                    <div v-for="(item_two, index_two) in item">
                                        <div v-for="(item_three, index_item_three) in item_two">
                                            <div class="information imgcenter" style="margin-top: 20px !important;">
                                                <img :src="['http://admin.bjthua.com/api' + [item_three.imageUrl]]"
                                                    alt="">
                                            </div>
                                            <div class="three">
                                                <p>&nbsp; {{ item_three.value }}&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="index == 'raman' && JSON.stringify(item) !== '{}'">
                                    <div class="titlebug" style="margin-top: 20px;">
                                        拉曼光谱仪
                                    </div>
                                    <div v-for="(ramanitem_two, ramanindex_two) in item" :key="ramanindex_two">
                                        <div v-for="(ramanitem_three, ramanindex_item_three) in ramanitem_two"
                                            :key="ramanindex_item_three">
                                            <div class="information imgcenter" style="margin-top: 20px !important;">
                                                <img 
                                                    :src="['http://admin.bjthua.com/api' + [ramanitem_three.imageUrl]]"
                                                    alt="">
                                            </div>
                                            <div class="three">
                                                <p>&nbsp; {{ ramanitem_three.value }}
                                                    &nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="index == 'xray' && JSON.stringify(item) !== '{}'">
                                    <div v-for="(xrayitem_two, xrayindex_two) in item" :key="xrayindex_two">
                                        <div class="titlebug" style="margin-top: 40px;">
                                            X射线荧光分析仪
                                        </div>
                                        <div v-for="(xrayitem_three, xrayindex_item_three) in xrayitem_two"
                                            :key="xrayindex_item_three">
                                            <div class="information imgcenter" style="margin-top: 20px !important;">
                                                <img :src="['http://admin.bjthua.com/api' + [xrayitem_three.imageUrl]]"
                                                    alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 结束 -->
                </div>
            </div>
            <!-- 遮罩 -->
            <div class="zhezhao">
                <img src="../imager/data/zhezhao.png" alt="">
            </div>
            <!-- 结束按钮 -->
            <div class="finish" @click="btnblock">
                <img src="../imager/data/delete.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { mapState} from 'vuex';

export default {
    data() {
        return {
           
            count: false
        };
    },
    computed: {
        ...mapState(['CulturalallData'])
    },
    mounted() {
        this.$store.commit("setData", 'b')
    },

    methods: {
        btnblock() {
            // console.log("我被触发了")
            this.$emit('numChange', this.count)
        }
    },
};
</script>

<style lang="scss" scoped>
.kaogu_box {
    overflow: hidden;
    position: absolute;
    z-index: 9999;
    top: 0;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    padding: 6vw;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .centent {
        position: relative;
        background: url('../imager/data/kaogu_back.png') no-repeat;
        background-size: 100%;
        height: 970px;
        width: 100%;
        margin-top: -200px;


    }

    .centent_boxtitle {
        width: 100%;
        height: 50px;
        position: absolute;
        // background: salmon;
        align-items: center;
        text-align: center;
        top: 35px;
        font-weight: 550;
        font-size: 32px;
        background: url('../imager/index/title.png');
        background-position: 20px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 8px;
    }

    .centent_box {
        width: 100%;
        height: 750px;
        position: absolute;
        // border: 1px solid red;
        top: 120px;
        box-sizing: border-box;
        padding: 0 10px;

        .box_centent {
            overflow-y: auto;
            // border: 1px solid red;
            height: 100%;
            box-sizing: border-box;
            padding: 0 20px;

            .information {
                margin-top: 30px;
                width: 100%;
                // height: 309px;

                .title {
                    margin-top: 40px;
                    text-align: center;
                    width: 280px;
                    color: #e4f7fb;
                    height: 61px;
                    font-size: 25px;
                    line-height: 61px;
                    letter-spacing: 6px;
                    font-weight: 500;
                    background-image: url('../imager/data/title2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                table {
                    margin-top: 20px;
                    margin-left: 5px;
                    width: 98%;
                    border-spacing: 0;
                    border-collapse: collapse;
                    font-size: 18px;

                }

                .left {
                    height: 80px;
                    width: 30%;
                    margin-top: -5px;
                    border: 3px solid #6fd9f1;
                    text-align: center;
                    color: #eefcff;
                    letter-spacing: 5px;
                    font-weight: 550;

                }

                .row {
                    padding: 20px;
                    text-indent: 40px;
                    text-align: left;
                }

                .right {
                    width: 65%;
                    letter-spacing: 5px;
                    border: 1px solid #6fd9f1;
                    text-align: center;
                    color: #eefcff;

                }
            }

            // 考古现场发现
            .information2 {
                width: 100%;
                margin-top: 43px;

                .title {
                    text-align: center;
                    width: 242px;
                    color: #e4f7fb;
                    height: 61px;
                    font-size: 25px;
                    line-height: 61px;
                    letter-spacing: 6px;
                    font-weight: 500;
                    background-image: url('../imager/data/title2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }

                .look_img {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row wrap;

                    .img_box {
                        margin-top: 20px;
                        width: 280px;
                        height: 180px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url('../imager/data/img_border.png') no-repeat;
                        background-size: 100% 100%;
                    }

                    .imgone {
                        position: relative;
                        width: 267px;
                        height: 160px;
                    }

                }


            }
        }
    }
}

.finish {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 65px;
        // height: 1;
    }
}

// 遮罩
.zhezhao {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    img {
        width: 100%;
        // height: 1;
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 16px;
    border-radius: 25px;
    margin-right: 20px;
    background: #3582aa;
}

/*定义滚动条轨道
//  内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 25px;
    // background-color:#F5F5F5;
    background-color: rgb(0, 0, 0, 0.1);
    // border: 1px solid #81aac9;

}

/*定义滑块
//  内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: #21bddf;
    background-color: #21bddf;
}

.kg {
    position: absolute;
    z-index: 9999;
}

.imgcenter {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 600px;
    }
}

.titlte_introduce {
    margin-top: 40px;
    color: #21bddf;
    font-size: 30px;
    font-weight: 600;
}




.material_new {
    width: 100%;
    height: 1000px;
    margin-top: 50px;

    .titileone {
        width: 100%;
        height: 40px;
        color: #ffffff;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 0.8vw;
        // border-bottom: 5px solid #8a453e;

        p {
            display: inline-block;
            width: auto;
            background: #3f4b59;
        }
    }

    .titile {
        margin-top: 20px;
        width: 100%;
        height: 80px;
        text-align: center;
        color: #8a453e;
        font-weight: 550;
        font-size: 45px;
        letter-spacing: 0.8vw;
        border-bottom: 5px solid #8a453e;
    }

    .three {
        margin-top: 40px;
        width: 100%;
        height: 80px;
        text-align: center;
        color: #8a453e;
        font-weight: 550;
        font-size: 10px;
        letter-spacing: 0.8vw;
     display: flex;
     justify-content: center;
        p {
            background: #77818c;
            display: inline-block;
            width: auto;
            color: white;
            height: 50px;
            // border-radius: 10px;
            line-height: 50px;
            font-size: 18px;
            text-align: center;
        }
    }
}









.titlebug {
    text-align: center;
    width: 260px;
    color: #e4f7fb;
    height: 61px;
    font-size: 25px;
    line-height: 61px;
    letter-spacing: 6px;
    font-weight: 550;
    background-image: url('../imager/data/title2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
</style>