// store/counter.js
import Vue from 'vue'
import Vuex from 'vuex'

//1.安装插件
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    CulturalallData:null,
    ylkey:null,
    id:null,
  },

  mutations: {
    setData(state, event) {
      if(event=='b'){
        let data= localStorage.getItem("datas");
        // console.log(JSON.parse(data))
        state.CulturalallData = JSON.parse(data);

      }else{
        state.CulturalallData = event;
       
      }
      // console.log( state.CulturalallData,'datasdatasdatasdatasdatasdatasdatasdatasdatas')
    },


    setylkey(state, id) {
      state.ylkey = id;
    },
    // 改id
    setId(state, id) {
      state.id = id;
      localStorage.setItem("id", id);
    },
   
   


  },

  actions: {
    updateId({ commit }, id) {
      // console.log(Object.prototype.toString.call(id).slice(8, -1).toLowerCase());
      commit('setId', id);


    },
    // increment(context) {
    //   context.commit('increment');
    // },
    // decrement(context) {
    //   context.commit('decrement');
    // }
  },

  getters: {
    getId: state => state.id,
    // doubleCount(state) {
    //   return state.count * 2;
    // }
  }
})

