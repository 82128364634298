import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/homea.vue'
import Genetic from '../views/Genetic data.vue'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    // path: '/?id',
    path:"/",
    name: 'home',
    meta:{
      index:0
    },
    component: HomeView
  },
  {
    // path: '/?id',
    path:"/id=:newsId",
    name: 'home',
    meta:{
      index:0
    },
    component: HomeView
  },
  // 基因数据
  {
    path: '/data',
    name: 'data',
    meta:{
      index:1
    },
    component:Genetic
  },
  // 文物修复
  // {
  //   path: '/pair',
  //   name: 'pair',
  //   meta:{
  //     index:2
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/relicre pair.vue')
  // },
  // 全景观物
  {
    path: '/panoramic',
    name: 'panoramic',
    meta:{
      index:2
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Panoramic view')
  },
  // 文献资料
  {
    path: '/Literature',
    name: 'Literature',
    meta:{
      index:3
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Literature.vue')
  },
  {
    path: '/a',
    name: 'a',
    meta:{
      index:3
    },
    component: () => import(/* webpackChunkName: "about" */ '@/components/render3D.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
